import React from 'react'

const Card = ({ data }) => {
  return data
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .map((wash) => {
      return (
        <div
          className="responsive-card border-0 bg-3 border-custom font-weight-bold text-7 shadow-custom m-2 px-4 py-3 bg-3 border-custom d-flex flex-column justify-content-between"
          key={wash.name + wash.points}
        >
          <h3 className="card-title text-grey font-heading font-weight-black mb-3  ">
            {wash.name}
          </h3>
          <h6>{wash.description}</h6>
          {wash.points ? (
            <div className="d-flex align-items-end justify-content-between">
              <div className="d-flex align-content-end">
                <img
                  alt="icon"
                  src="/public/coin.png"
                  style={{ width: '24px', height: '24px' }}
                />
                <h5 className="card-text align-bottom font-heading font-weight-semiBold mb-0 mx-2 pt-1">{`Earn ${wash.points} coins`}</h5>
              </div>
              <h2 className="card-text h1 text-primary align-bottom pt-1 lh-1 font-heading font-weight-black">{`${wash.price}`}</h2>
            </div>
          ) : (
            <div className="d-flex align-items-end justify-content-end">
              <h2 className="card-text h1 text-primary align-bottom pt-1 lh-1 font-heading font-weight-black">{`${wash.price}`}</h2>
            </div>
          )}
        </div>
      )
    })
}

export default Card

import React, { useState, useEffect } from 'react'
import { getActiveUsersReport } from '../../services/reportsApi.js'
import BasicTable from '../../components/Tables/BasicTable'
import dayjs from 'dayjs'

const ActiveUsersReport = () => {
  let [reportData, setReportData] = useState([])
  let [startDate, setStartDate] = useState('')
  let [endDate, setEndDate] = useState('')
  let [loading, setLoading] = useState(true)

  const todaysDate = () => {
    let d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  const threeMonthsBackDate = () => {
    var wayback = dayjs().subtract(7, 'month').format('YYYY-MM-DD')
    return wayback
  }

  useEffect(() => {
    setLoading(true)
    let localStartDate = threeMonthsBackDate()
    let localEndDate = todaysDate()
    setStartDate(localStartDate)
    setEndDate(localEndDate)
    getActiveUsersReport(localStartDate, localEndDate).then((res) => {
      setReportData(res)
      setLoading(false)
    })
  }, [])

  return (
    !loading && (
      <div className="row">
        <div className="col-md-12 mt-4">
          <BasicTable
            rowType={'customers'}
            records={reportData}
            fields={['name', 'contact_number']}
            headings={['name', 'contact_number']}
            crudEnabled={false}
            extraButtons={[]}
          />
        </div>
      </div>
    )
  )
}

export default ActiveUsersReport
